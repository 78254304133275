import { makeStyles } from '@material-ui/core/styles'

const ButtonStyles = makeStyles((theme) => ({
  default: {
    color: 'white',
    fontWeight: 500,
    fontSize: '0.8rem',
    textAlign: 'center',
    border: '1px solid transparent',
    borderRadius: 3,
    boxShadow: '2px 2px 8px 2px rgba(0,0,0,0.1)',
    margin: 'auto',
    fontFamily: 'Montserrat, sans-serif',
    outline: 'none',
    display: 'inline-block',
    '& a, & span': {
      color: 'white',
      padding: '0.8rem 3rem',
      display: 'block',
      textDecoration: 'none !important',
    },
  },
  green: {
    backgroundColor: '#016D81',
  },
}))
export default ButtonStyles
